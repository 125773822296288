import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateToken } from '@ngxs/store';

import { FirebaseHttpService } from '../../services/firebase-http.service';

import { DataItem } from './data-item.model';
import { GaSymbol } from '../../../ticker/stores/symbol/symbol.model';

export interface DataItemStateModel {
  items: { [symbol: string]: DataItem[] };
  loadingSymbols: Set<string>;
}

export class LoadDataItems {
  static readonly type = '[DataItem] Load';

  constructor(public symbol: GaSymbol) {
  }
}

export const DATA_ITEM_STATE_TOKEN = new StateToken<DataItemStateModel>('dataItem');

@State<DataItemStateModel>({
  name: DATA_ITEM_STATE_TOKEN,
  defaults: {
    items: {},
    loadingSymbols: new Set<string>(),
  },
})
@Injectable()
export class DataItemState {
  constructor(private httpClient: FirebaseHttpService) {
  }

  static getDataItemsBySymbol(ticker: string) {
    return createSelector([DataItemState], (state: DataItemStateModel) => {
      return state.items[ticker] || [];
    });
  }

  static getDataItemsBySymbolAndClass(symbol: string, className: string) {
    return createSelector([DataItemState], (state: DataItemStateModel) => {
      const items = state.items[symbol] || [];
      return items.filter((item) => item._class === className);
    });
  }

  @Action(LoadDataItems)
  async loadDataItems(ctx: StateContext<DataItemStateModel>, action: LoadDataItems) {
    const state = ctx.getState();
    const symbol = action.symbol;
    const ticker = symbol.ticker;

    if (state.items[ticker] || state.loadingSymbols.has(ticker)) {
      // Symbol already loaded or currently loading
      return;
    }

    try {
      // Mark symbol as loading
      ctx.patchState({
        loadingSymbols: new Set([...state.loadingSymbols, ticker])
      });

      // Load data using the callable cloud function
      const result = await this.httpClient.call('api', {
        method: 'get_fields',
        symbol: symbol.fullTicker,
        allowed: [symbol.class]
      }) as any;

      const dataItems: DataItem[] = result.data.map((i: any) => {
        return {
          ...i,
          description: this.patternToDescription(i.pattern),
          name: this.formatDataItemName(i),
        } as DataItem;
      });

      ctx.patchState({
        items: {
          ...state.items,
          [ticker]: dataItems,
        },
      });
    } finally {
      // Remove symbol from loading
      const updatedLoadingSymbols = new Set(state.loadingSymbols);
      updatedLoadingSymbols.delete(ticker);
      ctx.patchState({
        loadingSymbols: updatedLoadingSymbols,
      });
    }
  }

  private patternToDescription(pattern: string): string {
    if (pattern.includes('<_date_from>')) {
      return '|Date from|Date to|Symbol<br>Example last seven days: -7|-0|AAPL';
    } else if (pattern.includes('<symbol>')) {
      return '|Symbol<br>Example: |AAPL';
    } else {
      return '';
    }
  }

  private formatDataItemName(dataItem: any): string {
    if (dataItem._class !== 'ContinuousNewsDataField') {
      return dataItem.name;
    }

    const nameWithoutPrefix = dataItem.name.replace('NewsAggregator', '').trim();
    const symbolTag = dataItem.tags?.find((tag: string) => tag.startsWith('symbol:')) ?? '';
    const symbolName = symbolTag ? symbolTag.split(':')[1] + ' | ' : '';
    return symbolName
      ? `${symbolName}News Summarizer | ${nameWithoutPrefix}`
      : `News Summarizer | ${nameWithoutPrefix}`;
  }
}

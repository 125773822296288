import { Injectable, OnDestroy } from '@angular/core';
import { Auth, onAuthStateChanged, signOut, User } from '@angular/fire/auth';
import { doc, Firestore, getDoc, onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { RouteTrackerService } from '../../services/route-tracker.service';

import { Subscription } from './subscription.model';
import { GaUser, UserProfile } from './user-profile.model';

export interface UserStateModel {
  user: GaUser;
  isAdmin: boolean;
  profile: UserProfile | null;
  subscription: Subscription | null;
}

export class SetUserAndLoadProfile {
  static readonly type = '[User] Set';

  constructor(public user: User | null) {
  }
}

export class LoadUserProfile {
  static readonly type = '[User] Load Profile';

  constructor(public userId: string) {
  }
}

export class LoadUserSubscription {
  static readonly type = '[User] Load Subscription';

  constructor(public subscriptionName: string) {
  }
}

export class Logout {
  static readonly type = '[User] Logout';

  constructor() {
  }
}

export const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    user: {
      userId: null,
      isAuthenticated: false,
      isAnonymous: true,
      displayName: null,
      photoURL: null,
      email: null
    } as GaUser,
    isAdmin: false,
    profile: null,
    subscription: null
  }
})
@Injectable()
export class UserState implements OnDestroy, NgxsOnInit {
  private userProfileListener: Unsubscribe | null = null;

  constructor(
    private db: Firestore,
    private auth: Auth,
    private routeTrackerService: RouteTrackerService,
    private toastr: ToastrService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    onAuthStateChanged(
      this.auth,
      (user) => {
        this.setUserAndLoadProfile(ctx, new SetUserAndLoadProfile(user));
      },
      (error) => {
        console.error('Error listening to auth state changes:', error);
        this.toastr?.error(JSON.stringify(error), 'Error in authentication');
      }
    );
  }

  ngOnDestroy(): void {
    this.userProfileListener?.();
  }

  @Selector()
  static user(state: UserStateModel): GaUser {
    return state.user;
  }

  @Selector()
  static isAdmin(state: UserStateModel): boolean {
    return state.isAdmin;
  }

  @Selector()
  static isAuthenticated(state: UserStateModel): boolean {
    return state.user.isAuthenticated;
  }

  @Selector()
  static isAnonymous(state: UserStateModel): boolean {
    return state.user.isAnonymous;
  }

  @Selector()
  static displayName(state: UserStateModel): string | null {
    return state.user.displayName;
  }

  @Selector()
  static getUserId(state: UserStateModel): string | null {
    return state.user.userId;
  }

  @Selector()
  static getUserProfile(state: UserStateModel) {
    return state.profile;
  }

  @Selector()
  static getUserSubscription(state: UserStateModel) {
    return state.subscription;
  }

  @Selector()
  static isMaxPromptsReached(state: UserStateModel): boolean {
    const { profile, subscription } = state;
    return profile && subscription && profile.prompts >= subscription.maxPrompts || false;
  }

  @Selector()
  static isMaxActiveReached(state: UserStateModel): boolean {
    const { profile, subscription } = state;
    return profile && subscription && profile.active_runs >= subscription.maxActive || false;
  }

  @Selector()
  static isMaxBacktestsReached(state: UserStateModel): boolean {
    const { profile, subscription } = state;
    return profile && subscription && profile.backtests >= subscription.maxBacktests || false;
  }

  @Action(SetUserAndLoadProfile)
  setUserAndLoadProfile(ctx: StateContext<UserStateModel>, { user }: SetUserAndLoadProfile) {
    if (user) {
      ctx.patchState({
        user: {
          userId: user.uid,
          isAnonymous: user.isAnonymous,
          displayName: user.displayName,
          photoURL: user.photoURL,
          email: user.email,
          isAuthenticated: true
        }
      });
      this.routeTrackerService.navigateToPrevious();
      this.loadUserProfile(ctx, new LoadUserProfile(user.uid || ''));

      // Check admin role
      user.getIdTokenResult().then((token) => {
        ctx.patchState({
          isAdmin: token.claims['admin'] as boolean || true
        });
      });
    } else {
      ctx.patchState({
        user: {
          userId: null,
          isAnonymous: true,
          displayName: null,
          photoURL: null,
          email: null,
          isAuthenticated: false
        },
        profile: null,
        subscription: null
      });
    }
  }

  @Action(LoadUserProfile)
  loadUserProfile(ctx: StateContext<UserStateModel>, action: LoadUserProfile) {
    return new Promise((resolve, reject) => {
      this.userProfileListener?.();
      this.userProfileListener = onSnapshot(doc(this.db, 'users', action.userId), async (doc) => {
        let profile = null;
        if (doc.exists()) {
          profile = doc.data() as UserProfile;
          ctx.patchState({ profile });
          await this.loadUserSubscription(ctx, new LoadUserSubscription(profile.subscription));
        } else {
          ctx.patchState({ profile: null });
        }

        resolve(profile);
      }, (error) => {
        console.error('Error loading user profile', error);
        reject(error);
      });
    });
  }

  @Action(LoadUserSubscription)
  async loadUserSubscription(ctx: StateContext<UserStateModel>, action: LoadUserSubscription) {
    const sDoc = await getDoc(doc(this.db, 'subscriptions', action.subscriptionName));
    if (sDoc.exists()) {
      const subscription = sDoc.data() as Subscription;
      ctx.patchState({ subscription });
    } else {
      ctx.patchState({ subscription: null });
    }
  }

  @Action(Logout)
  logout(): Promise<void> {
    return signOut(this.auth);
  }
}

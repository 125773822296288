import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, noop, Observable } from 'rxjs';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import { DataGeneratorSelectors } from '../../stores/data-generator/data-generator.selector';
import { MatSort } from '@angular/material/sort';
import { LoadMoreGenerators } from '../../stores/data-generator/data-generator.action';
import { UserState } from '@core/stores/user/user.state';
import { MatChipListboxChange } from '@angular/material/chips';
import { getSymbolTag } from "@data-generator/data-generator.utils";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { map } from "rxjs/operators";

const ACTIONS_COLUMN = 'actions';
const EXPAND_COLUMN = 'expand';
const MOBILE_COLUMNS = ['scope', 'name', 'status', 'runFrequency'];
const NON_MOBILE_COLUMNS = ['startedAt', 'updatedAt'];
const ADMIN_COLUMNS = ['owner', 'cost'];

export enum FilterType {
  ALL = 'all',
  STOCKS = 'stocks',
  CRYPTOS = 'cryptos',
  TOPICS = 'topics',
  MINE = 'mine'
}

@Component({
  selector: 'app-data-generator-overview',
  templateUrl: './data-generator-overview.component.html',
  styleUrls: ['./data-generator-overview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataGeneratorOverviewComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [...MOBILE_COLUMNS, ...NON_MOBILE_COLUMNS, ACTIONS_COLUMN];
  detailColumns: string[] = [];
  dataSource = new MatTableDataSource<DataGenerator>([]);
  selected: DataGenerator | null = null;
  selectedFilter: FilterType = FilterType.ALL;
  FilterType = FilterType;
  isAuthenticated$: Observable<boolean> = this.store.select(UserState.isAuthenticated);
  isAdmin$ = this.store.select(UserState.isAdmin);
  isMobile = false;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  private originalData: DataGenerator[] = [];
  private pageSize = 10;
  private currentSymbol: string | null = null;
  private currentTopic: string | null = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.currentSymbol = params['symbol']?.toLowerCase();
      this.currentTopic = params['name']?.toLowerCase();
      this.dataSource = new MatTableDataSource<DataGenerator>();
      this.loadGenerators();
    });

    combineLatest([this.breakpointObserver.observe([Breakpoints.Handset]), this.isAdmin$]).subscribe(([breakpointState, isAdmin]) => {
      if (breakpointState.matches) {
        this.displayedColumns = [...MOBILE_COLUMNS, ACTIONS_COLUMN, EXPAND_COLUMN];
        this.detailColumns = [...NON_MOBILE_COLUMNS, ...isAdmin ? ADMIN_COLUMNS : []];
        this.isMobile = true;
      } else {
        this.displayedColumns = [...MOBILE_COLUMNS, ...NON_MOBILE_COLUMNS, ...isAdmin ? ADMIN_COLUMNS : [], ACTIONS_COLUMN];
        this.detailColumns = [];
        this.isMobile = false;
        this.selected = null;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe(() => {
      if (this.isLastPage()) {
        this.loadMoreGenerators();
      }
    });

    this.loadMoreGenerators();
  }

  viewGenerator(g: DataGenerator) {
    this.router.navigate(['../view', g.news_summariser_id], { relativeTo: this.route }).then(noop);
  }

  createGenerator() {
    this.router.navigate(['../create'], { relativeTo: this.route }).then(noop);
  }

  getScope(generator: DataGenerator): string {
    const scopeTag = generator.tags?.find(tag =>
      tag.startsWith('topic:') || tag.startsWith('symbol:')
    );
    return scopeTag ? scopeTag.split(':')[1] : 'N/A';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.applyFilters();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterChange(event: MatChipListboxChange) {
    this.selectedFilter = event.value;
    this.applyFilters();
  }

  formatCost(cost: number | undefined): string {
    if (!cost) return '$0.00';
    return `$${cost.toFixed(3)}`;
  }

  login() {
    this.router.navigate(['/login']).then(noop);
  }

  toggleRow(event: Event, element: DataGenerator): void {
    event.stopPropagation();
    this.selected = this.selected === element ? null : element;
  }

  private loadGenerators() {
    this.store.select(DataGeneratorSelectors.selectAll).pipe(
      map(generators => this.filterGenerators(generators))
    ).subscribe(generators => {
      this.originalData = [...generators];
      this.dataSource.data = this.originalData;
      this.dataSource.paginator = this.paginator;
    });
  }

  private filterGenerators(generators: DataGenerator[]): DataGenerator[] {
    if (!this.currentTopic && !this.currentSymbol) {
      return generators;
    }
    return generators.filter(generator =>
      generator.tags?.some(tag => {
        if (this.currentTopic) {
          return tag.toLowerCase() === `topic:${this.currentTopic}`;
        } else if (this.currentSymbol) {
          return tag.toLowerCase() === `symbol:${this.currentSymbol}`;
        }
        return true;
      })
    );
  }

  private isLastPage(): boolean {
    const maxPages = Math.ceil(this.dataSource.data.length / this.pageSize);
    return this.paginator.pageIndex === maxPages - 1;
  }

  private loadMoreGenerators() {
    const tag = this.currentSymbol ? getSymbolTag(this.currentSymbol) : null;
    this.store.dispatch(new LoadMoreGenerators(tag, false));
  }

  private applyFilters() {
    let filteredData = [...this.originalData];

    const filterFunctions = {
      [FilterType.STOCKS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('symbol:')),
      [FilterType.CRYPTOS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('crypto:')),
      [FilterType.TOPICS]: (g: DataGenerator) => g.tags?.some(tag => tag.startsWith('topic:')),
      [FilterType.MINE]: (g: DataGenerator) => g.isOwner
    };

    if (this.selectedFilter !== FilterType.ALL) {
      const filterFn = filterFunctions[this.selectedFilter];
      if (filterFn) {
        filteredData = filteredData.filter(filterFn);
      }
    }

    if (this.dataSource.filter) {
      this.dataSource.filter = this.dataSource.filter.toLowerCase();
    }

    this.dataSource.data = filteredData;
  }
}

import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { provideAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { AuthModule, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { provideDatabase } from '@angular/fire/database';
import { provideFirestore } from '@angular/fire/firestore';
import { provideFunctions } from '@angular/fire/functions';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router } from "@angular/router";
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from "@sentry/angular";
import { ToastrModule } from 'ngx-toastr';

import { analytics, auth, database, db, firebaseApp, functions, isProduction } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GaCoreModule } from './core/core.module';
import { MaterialModule } from './core/material.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    AuthModule,
    AngularFireAuthModule,
    NgxsModule.forRoot([], {
      developmentMode: isProduction
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      disableTimeOut: false,
      timeOut: 3000,
    }),
    GaCoreModule,
    HomeModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    PercentPipe,
    provideAnimationsAsync(),
    { provide: FIREBASE_OPTIONS, useValue: firebaseApp.options },
    provideAnalytics(() => analytics),
    provideFirebaseApp(() => firebaseApp),
    provideFirestore(() => db),
    provideFunctions(() => functions),
    provideAuth(() => auth),
    provideDatabase(() => database),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      })
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<app-data-generator-breadcrumb></app-data-generator-breadcrumb>
<div class="generator-overview-container">
  <h1>News Summarizer</h1>

  <mat-chip-listbox (change)="filterChange($event)" [value]="selectedFilter" class="filter-chips">
    <mat-chip-option [value]="FilterType.ALL">All</mat-chip-option>
    <mat-chip-option [value]="FilterType.STOCKS">Stocks</mat-chip-option>
    <mat-chip-option [value]="FilterType.CRYPTOS">Cryptos</mat-chip-option>
    <mat-chip-option [value]="FilterType.TOPICS">Topics</mat-chip-option>
    <mat-chip-option *ngIf="isAuthenticated$ | async" [value]="FilterType.MINE">Mine</mat-chip-option>
  </mat-chip-listbox>

  <div class="table-controls">
    <mat-form-field class="search-field">
      <mat-label>Search</mat-label>
      <input (keyup)="applyFilter($event)" matInput placeholder="Search generators">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" class="generator-table" matSort multiTemplateDataRows>
    <ng-container matColumnDef="scope">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Scope</mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.description"> {{ getScope(generator) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.description"> {{ generator.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let generator">
        <app-data-generator-status [generator]="generator"></app-data-generator-status>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="runFrequency">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Run Frequency</mat-header-cell>
      <mat-cell *matCellDef="let generator"> {{ generator.runFreq }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Started At</mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="(generator?.startedAt | date:'medium') || ''">
        {{ generator.startedAt | date:'dd/MM/yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="(generator?.updatedAt | date:'medium') || ''">
        {{ generator.updatedAt | date:'dd/MM/yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
      <mat-cell *matCellDef="let generator" [matTooltip]="generator.ownerId">
        <span class="owner-cell">{{ generator.ownerId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cost</mat-header-cell>
      <mat-cell *matCellDef="let generator"> {{ formatCost(generator.cost) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let generator">
        <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{data: generator}"
                [matMenuTriggerFor]="rowMenu" aria-label="Actions" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expand">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let generator">
        <button (click)="toggleRow($event, generator)" mat-icon-button>
          <mat-icon>{{ selected === generator ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="detail">
      <mat-cell *matCellDef="let generator" [attr.colspan]="displayedColumns.length" class="generator-detail">
        <strong>Started At</strong>
        <span>{{ generator.startedAt | date:'dd/MM/yyyy' }}</span>
        <strong>Updated At</strong>
        <span>{{ generator.updatedAt | date:'dd/MM/yyyy' }}</span>
        @if (isAdmin$ | async) {
          <strong>Owner</strong>
          <span>{{ generator.ownerId }}</span>
          <strong>Cost</strong>
          <span>{{ formatCost(generator.cost) }}</span>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row (click)="viewGenerator(row)" *matRowDef="let row; columns: displayedColumns;"
             class="generator-row">
    </mat-row>
    <mat-row *matRowDef="let row; columns: ['detail']" [@detailExpand]="row == selected ? 'expanded' : 'collapsed'"
             [class.expanded-row]="selected === row" class="detail-row">
    </mat-row>
  </mat-table>

  <mat-menu #rowMenu="matMenu">
    <ng-template let-generator="data" matMenuContent>
      <app-data-generator-menu
        [generator]="generator"
        [isAdmin]="false">
      </app-data-generator-menu>
    </ng-template>
  </mat-menu>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" aria-label="Select page of generators">
  </mat-paginator>

  <div class="new-wrapper">
    <ng-container *ngIf="isAuthenticated$ | async; else loginButton">
      <button (click)="createGenerator()" class="add-button"
              color="primary"
              mat-raised-button
              title="Create new summarizer">
        New
      </button>
    </ng-container>
    <ng-template #loginButton>
      <p>Login to create and edit news summarizers</p>
      <button (click)="login()"
              mat-raised-button>
        Login
      </button>
    </ng-template>
  </div>
</div>

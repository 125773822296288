import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataGenerator } from '../../stores/data-generator/data-generator.model';
import {
  CreateDataGenerator,
  DeleteDataGenerator,
  LoadDataGenerator,
  UpdateDataGenerator
} from '../../stores/data-generator/data-generator.action';
import { Store } from '@ngxs/store';
import { DataGeneratorSelectors } from '../../stores/data-generator/data-generator.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../core/components/confirmation-dialog/confirmation-dialog.component';
import { CronOptions } from 'ngx-cron-editor';
import { DEFAULT_CRON_OPTIONS, DEFAULT_PROMPT } from '../../data-generator.utils';
import { UserState } from '../../../core/stores/user/user.state';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { createSymbol, GaSymbol } from '../../../ticker/stores/symbol/symbol.model';

type EditMode = 'create' | 'edit' | 'clone';

@Component({
  selector: 'app-data-generator-editing-v2',
  templateUrl: './data-generator-editing-v2.component.html',
  styleUrls: ['./data-generator-editing-v2.component.scss']
})
export class DataGeneratorEditingV2Component implements OnInit {
  generatorForm: FormGroup = this.fb.group({});
  summarizerId: string | null = null;
  currentGenerator$: Observable<DataGenerator | undefined> = of(undefined);
  isExpanded = false;
  showInputs = false;
  cronOptions: CronOptions = DEFAULT_CRON_OPTIONS;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  mode: EditMode = 'create';
  symbol: GaSymbol | undefined;

  isAdmin$ = this.store.select(UserState.isAdmin);

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog) {

    this.initForm();

    // Determine mode from route
    const url = this.router.url;
    if (url.includes('/edit/')) {
      this.mode = 'edit';
    } else if (url.includes('/clone/')) {
      this.mode = 'clone';
    }

    // Handle route parameters
    this.route.params.subscribe(params => {
      const symbol = params['symbol'];
      if (symbol) {
        // Check stock or crypto in url
        const assetClass = this.router.url.includes('stock') ? 'stock' : 'crypto';
        this.symbol = createSymbol(assetClass, symbol, {} as any);
        this.showInputs = true;
      } else {
        this.symbol = undefined;
        this.showInputs = false;
      }
    });
  }

  get saveButtonLabel(): string {
    switch (this.mode) {
      case 'edit':
        return 'Update';
      case 'clone':
        return 'Create Copy';
      default:
        return 'Create';
    }
  }

  ngOnInit(): void {
    this.summarizerId = this.route.snapshot.paramMap.get('summarizerId');
    if (!this.summarizerId) return;

    this.currentGenerator$ = this.store.select(DataGeneratorSelectors.selectById(this.summarizerId));
    this.store.dispatch(new LoadDataGenerator(this.summarizerId));
    this.currentGenerator$.subscribe(generator => {
      if (generator) {
        this.patchFormWithGenerator(generator);
      }
    });
  }

  async onSave(): Promise<void> {
    if (this.generatorForm.valid) {
      const generatorData = {
        ...this.generatorForm.value,
        prompt: {
          template_body: this.generatorForm.value.promptBody
        }
      };

      if (this.mode === 'edit' && this.summarizerId) {
        await this.store.dispatch(new UpdateDataGenerator({
          ...generatorData,
          news_summariser_id: this.summarizerId
        }));
      } else {
        await this.store.dispatch(new CreateDataGenerator({
          ...generatorData,
          timezone: 'America/New_York'
        }));
      }

      this.router.navigate(['/summarizers']);
    }
  }

  async onDelete(): Promise<void> {
    if (this.summarizerId) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Delete Summarizer',
          message: 'Are you sure you want to delete this summarizer?',
          confirmText: 'Delete',
          cancelText: 'Cancel'
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          await this.store.dispatch(new DeleteDataGenerator(this.summarizerId!));
          this.router.navigate(['/summarizers']);
        }
      });
    }
  }

  onCancel(): void {
    this.router.navigate(['/summarizers']);
  }

  toggleInputs(): void {
    this.showInputs = !this.showInputs;
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const currentTags = this.generatorForm.get('tags')?.value || [];

    if (value) {
      this.generatorForm.patchValue({
        tags: [...currentTags, value]
      });
    }

    event.chipInput!.clear();
  }

  removeTag(tagToRemove: string): void {
    const currentTags = this.generatorForm.get('tags')?.value || [];
    const updatedTags = currentTags.filter((tag: string) => tag !== tagToRemove);

    this.generatorForm.patchValue({
      tags: updatedTags
    });
  }

  onClone(): void {
    this.router.navigate(['/summarizers/clone', this.summarizerId]);
  }

  private initForm() {
    this.generatorForm = this.fb.group({
      name: ['New Summarizer', Validators.required],
      description: [''],
      runFreq: ['45 15 * * MON', Validators.required],
      promptBody: [DEFAULT_PROMPT, Validators.required],
      tags: [[]],
      startedAt: [new Date()]
    });

    // Disable prompt editing in edit mode
    if (this.mode === 'edit') {
      this.generatorForm.get('promptBody')?.disable();
    }
  }

  private patchFormWithGenerator(generator: DataGenerator) {
    // In clone mode, append "- Copy" to name
    const name = this.mode === 'clone' ? `${generator.name} - Copy` : generator.name;

    this.generatorForm.patchValue({
      name,
      description: generator.description,
      runFreq: generator.runFreq,
      promptBody: generator.prompt.template_body,
      tags: generator.tags || [],
      startedAt: generator.startedAt ? new Date(generator.startedAt) : new Date()
    });
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom, mergeMap, Observable } from 'rxjs';
import { LoadDataGenerator } from "@data-generator/stores/data-generator/data-generator.action";
import { switchMap } from "rxjs/operators";
import { DataGeneratorSelectors } from "@data-generator/stores/data-generator/data-generator.selector";

interface Breadcrumb {
  label: string;
  url: string;
}

type RouteType = 'stock' | 'crypto' | 'topic';

@Component({
  selector: 'app-data-generator-breadcrumb',
  templateUrl: './data-generator-breadcrumb.component.html',
  styleUrls: ['./data-generator-breadcrumb.component.scss']
})
export class DataGeneratorBreadcrumbComponent {
  breadcrumbs$: Observable<Breadcrumb[]>;
  private readonly BASE_URL = '/summarizers';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
    this.breadcrumbs$ = this.router.events.pipe(
      mergeMap(() => this.buildBreadcrumbs())
    );
  }

  private async buildBreadcrumbs(): Promise<Breadcrumb[]> {
    const breadcrumbs: Breadcrumb[] = [
      { label: 'Home', url: '/news' }
    ];

    const url = this.router.url;

    // Handle symbol-based routes (stock/crypto)
    if (this.isSymbolBasedRoute(url)) {
      this.addSymbolBasedBreadcrumbs(breadcrumbs, url);
    }
    // Handle topic routes
    else if (url.includes(`${this.BASE_URL}/topic/`)) {
      this.addTopicBreadcrumbs(breadcrumbs);
    }
    // Handle base summarizer routes
    else if (url.includes(this.BASE_URL)) {
      breadcrumbs.push({ label: 'Summarizers', url: this.BASE_URL });

      // Add Create breadcrumb
      if (url.includes('/create')) {
        breadcrumbs.push({ label: 'Create', url: `${this.BASE_URL}/create` });
      }
    }

    // Handle summarizer-specific routes (view/edit/clone)
    await this.addSummarizerSpecificBreadcrumbs(breadcrumbs, url);

    return breadcrumbs;
  }

  private isSymbolBasedRoute(url: string): boolean {
    return url.includes(`${this.BASE_URL}/stock/`) ||
      url.includes(`${this.BASE_URL}/crypto/`);
  }

  private getRouteType(url: string): RouteType | null {
    if (url.includes(`${this.BASE_URL}/stock/`)) return 'stock';
    if (url.includes(`${this.BASE_URL}/crypto/`)) return 'crypto';
    if (url.includes(`${this.BASE_URL}/topic/`)) return 'topic';
    return null;
  }

  private addSymbolBasedBreadcrumbs(breadcrumbs: Breadcrumb[], url: string): void {
    const routeType = this.getRouteType(url);
    const symbol = this.activatedRoute.snapshot.paramMap.get('symbol');

    if (symbol && routeType) {
      const baseUrl = `${this.BASE_URL}/${routeType}/${symbol}/list`;
      breadcrumbs.push(
        { label: 'Summarizers', url: baseUrl },
        { label: symbol.toUpperCase(), url: baseUrl }
      );
    }
  }

  private addTopicBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    const topic = this.activatedRoute.snapshot.paramMap.get('name');
    if (topic) {
      const baseUrl = `${this.BASE_URL}/topic/${topic}/list`;
      const topicName = topic.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      breadcrumbs.push(
        { label: 'Summarizers', url: baseUrl },
        { label: topicName, url: baseUrl }
      );
    }
  }

  private async addSummarizerSpecificBreadcrumbs(breadcrumbs: Breadcrumb[], url: string): Promise<void> {
    const summarizerId = this.activatedRoute.snapshot.paramMap.get('summarizerId');

    if (!summarizerId) return;

    const generator = await this.getGenerator(summarizerId);

    if (!generator) return;

    breadcrumbs.push({
      label: generator.name,
      url: `${this.BASE_URL}/view/${summarizerId}`
    });

    const actionMap = {
      '/edit/': 'Edit',
      '/clone/': 'Clone'
    };

    for (const [path, label] of Object.entries(actionMap)) {
      if (url.includes(path)) {
        breadcrumbs.push({
          label,
          url: `${this.BASE_URL}${path}${summarizerId}`
        });
        break;
      }
    }
  }

  /**
   * Try to get the generator with the given summarizerId from the local store. If it can't be found, try to load it.
   */
  private async getGenerator(summarizerId: string) {
    let generator = this.store.selectSnapshot(DataGeneratorSelectors.selectById(summarizerId));
    if (!generator) {
      generator = await firstValueFrom(this.store.dispatch(new LoadDataGenerator(summarizerId)).pipe(
        switchMap(() => this.store.select(DataGeneratorSelectors.selectById(summarizerId)))
      ));
    }
    return generator;
  }
}

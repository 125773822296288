import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { FirebaseHttpService } from '../../../core/services/firebase-http.service';
import { Logout, UserState } from '../../../core/stores/user/user.state';
import { distinctUntilChanged, filter, map, Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isAdmin$ = this.store.select(UserState.isAdmin);
  isAuthenticated$ = this.store.select(UserState.isAuthenticated);
  home$: Observable<string>;

  constructor(
    private store: Store,
    private router: Router,
    private fbHttps: FirebaseHttpService) {
    this.fbHttps.call('api', { method: 'init' });

    this.home$ = this.router.events.pipe(
      takeUntilDestroyed(),
      filter((event => event instanceof NavigationEnd)),
      map(event => {
        const url = (event as NavigationEnd).url;
        if (url.includes('ticker')) {
          return '/news';
        } else {
          return '/';
        }
      }),
      distinctUntilChanged()
    );
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}

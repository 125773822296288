import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

const DEFAULT_DESCRIPTION = `Explore real-time stock insights and smart
financial news powered by AI and machine learning. Get a competitive
edge with automated investment analysis.`;


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title, private metaService: Meta) {
    // Set the title of the page
    this.titleService.setTitle('GPT Analyst');

    // Set meta tags for the page
    this.metaService.addTags([{
      name: 'description',
      content: DEFAULT_DESCRIPTION
    }]);
  }

  // Method to set title and description
  public setMetaTags(title: string, description: string): void {
    if (title) this.titleService.setTitle('GPT Analyst | ' + title);

    if (description) this.metaService.updateTag({
      name: 'description',
      content: `${DEFAULT_DESCRIPTION} ${description}`
    });
  }

  // Method to determine if the current user is a crawler
  public isCrawler(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /bot|googlebot|crawler|spider|robot|inspection|crawling/i.test(userAgent);
  }
}

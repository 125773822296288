import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHttpService {

  constructor(private functions: Functions) {
  }

  call<ResponseData>(functionName: string, data: any) {
    return httpsCallable<unknown, ResponseData>(this.functions, functionName)(data);
  }
}
